import logo from "./logo.svg";
import downSite from "./down-site.svg";
import logoVerde from "./logo-verde.svg";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <div className="container">
        <img src={logoVerde} className="App-logo" alt="header-logo" />
        <div className="content-flex">
          <div className="content">
            <h1 className="title">
              Cumplomex S.A. de C.V. cerró sus operaciones
            </h1>
            <p className="text">
              Ya que no obtuvimos autorización para constituirnos como
              Institución de Financiamiento Colectivo por parte de la Comisión
              Nacional Bancaria y de Valores, Cumplomex, S.A. de C.V. dejó de
              operar.
            </p>
            <p className="text">
              Si tienes algún saldo no retirado o alguna duda al respecto,
              escríbenos al correo electrónico{" "}
              <a href="mailto:contactanos@cumplo.com" className="mail">
                contactanos@cumplo.com.
              </a>
            </p>
            <p className="text">
              ¿Tienes un negocio y necesitas liquidez? En Cumplo podemos
              facilitarte el acceso a financiamiento. Conoce nuestra empresa.
            </p>
            <a className="button" href="https://cumplo.mx">
              Ir a Cumplo
            </a>
          </div>
          <img src={downSite} className="down-site" alt="logo" />
        </div>
      </div>
      <footer className="footer">
        <img src={logo} className="App-logo" alt="footer-logo" />
      </footer>
    </div>
  );
}

export default App;
